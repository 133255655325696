.button-custom-upload {
  background: #888;
  border: none;
  padding: 2px 5px;
  border-radius: 2px;
}
/* .file-upload {
  background: #e5e5e5;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  color: #000;
  font-weight: bold;
  margin: 5px auto;
  overflow: hidden;
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
} */
/* .file-upload:hover,
.file-upload:active,
.file-upload:focus {
  background: #00a2a4;
  cursor: pointer;
} */
/* .file-upload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: pointer;
} */

/* input[type="file"] {
  position: fixed;
  right: 100%;
  bottom: 100%;
} */
/* .custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
} */
