* {
  margin: 0;
  padding: 0;
  --adm-color-background: 90deg, rgba(76, 175, 80, 1) 0%, rgba(49, 220, 255, 1) 100%;
}

:root {
  margin: 0;
  padding: 0;
  --main-bg-color: brown;
  --main-gradient-color: 90deg, rgba(76, 175, 80, 1) 0%, rgba(49, 220, 255, 1) 100%;
}

*,
:root,
html {
  font-family: 'Poppins', sans-serif;
}

body {
  background: radial-gradient(150% 46.24% at 23.47% 8.31%, #0E3C21 0%, #052A25 48.75%, #050505 94.67%);
  background-attachment: fixed;
}

#notification {
  background: #ababab;
  bottom: 15px;
  color: #fff;
  display: none;
  padding: 16px;
  position: fixed;
  right: 8px;
  width: 300px;
  z-index: 999999;
  text-align: center;
}

/* #notification p {
  width: auto;
  transition-timing-function: linear;
  white-space: nowrap;
} */

#notification.show {
  display: block;
}

.container {
  flex: 1;
  margin: 0 0 80px 0;
}

.bottom {
  flex: 0;
  border-top: solid 1px #777;
  position: fixed;
  z-index: 1000;
  background-color: #efefef;
  bottom: 0;
  /* padding: 10px;
  left: 0;
  right: 0;
  */
}

.adm-list-item-content {
  border-top: solid 0px #2b2b2b !important;
}

.profile-pic img {
  height: 40px;
  width: 40px;
}

.button {
  border: 0 transparent;
  border-radius: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #050505;
}

.button {
  border: 0 transparent;
  border-radius: 10px;
}

.expense {
  /* background-color: #ef4444; */
  background-color: rgba(0, 0, 0, 0.3);
  padding: 9px;
  border-radius: 10px;
  color: #ef4444;
  opacity: 0.5;
}

.income {
  /* background-color: rgba(76, 175, 80, 1); */
  background-color: rgba(0, 0, 0, 0.3);
  padding: 9px;
  border-radius: 10px;
  color: rgba(76, 175, 80, 1);
  opacity: 0.5;
}


.more {
  background: -moz-linear-gradient(90deg, rgba(76, 175, 80, 1) 0%, rgba(49, 220, 255, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(76, 175, 80, 1) 0%, rgba(49, 220, 255, 1) 100%);
  background: -o-linear-gradient(90deg, rgba(76, 175, 80, 1) 0%, rgba(49, 220, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#31DCFF', endColorstr='#4CAF50', GradientType=0);
  background: -ms-linear-gradient(90deg, rgba(76, 175, 80, 1) 0%, rgba(49, 220, 255, 1) 100%);
  background: linear-gradient(90deg, rgba(76, 175, 80, 1) 0%, rgba(49, 220, 255, 1) 100%);
}

.aport {
  box-sizing: border-box;
  background: linear-gradient(264.46deg, rgba(8, 241, 159, 0.6) 4.57%, rgba(46, 176, 131, 0.6) 98.05%);
  border: 1px solid #07CD87;
  border-radius: 10px;
  height: 70px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: #FFFFFF;
  transition: all 0.7s;
}

.withdrawal {
  box-sizing: border-box;
  background: rgba(121, 0, 0, 0.6);
  border: 1px solid rgba(121, 0, 0, 0.6);
  border-radius: 10px;
  height: 70px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: #FFFFFF;
  transition: all 0.10s;
}

.layout-login {
  border: 0 transparent;
  border-radius: 10px;

  background: -moz-linear-gradient(90deg, rgba(76, 175, 80, 1) 0%, rgba(49, 220, 255, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(76, 175, 80, 1) 0%, rgba(49, 220, 255, 1) 100%);
  background: -o-linear-gradient(90deg, rgba(76, 175, 80, 1) 0%, rgba(49, 220, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#31DCFF', endColorstr='#4CAF50', GradientType=0);
  background: -ms-linear-gradient(90deg, rgba(76, 175, 80, 1) 0%, rgba(49, 220, 255, 1) 100%);
  background: linear-gradient(90deg, rgba(76, 175, 80, 1) 0%, rgba(49, 220, 255, 1) 100%);
}

.layout-app {
  /* height: 100vh;
  width: 100vw; */
}

.layout-login {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background: -moz-radial-gradient(circle at 18% 31%, rgb(11, 48, 45) 0%, rgba(0, 0, 0, 1) 74%);
  background: -webkit-radial-gradient(circle at 18% 31%, rgba(11, 48, 45, 1) 0%, rgba(0, 0, 0, 1) 74%);
  background: -o-radial-gradient(circle at 18% 31%, rgba(11, 48, 45, 1) 0%, rgba(0, 0, 0, 1) 74%);
  background: -ms-radial-gradient(circle at 18% 31%, rgba(11, 48, 45, 1) 0%, rgba(0, 0, 0, 1) 74%);
  background: radial-gradient(circle at 18% 31%, rgba(11, 48, 45, 1) 0%, rgba(0, 0, 0, 1) 74%);
  background-image: url('./assets/background-page-login.png');
  background-size: cover;
}

.layout-login .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 270px;
}

.layout-login .button {
  height: 55px;
  /* position: absolute; */
  /* bottom: 25px; */
  width: 100%;
}

.layout-login .text {
  margin: 0 0 20px 0px;
  background: -webkit-linear-gradient(90deg, rgba(76, 175, 80, 1) 20%, rgba(49, 220, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  width: 330px;
  text-align: center;

}

.layout-login .middle {
  height: 400px;
  width: 90vw;
  justify-content: center;
  flex: 1;
}

.velas {
  /* background-image: url('./assets/velas.png'); */
  width: 100%;
  height: 100%;
  position: fixed;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100%;
  background-position-y: 70px;
  z-index: -1;
}

.form-login {
  margin-bottom: 20px;
}

.adm-list-default .adm-list-body {
  border-top: 0px solid transparent;
  border-bottom: 0px solid transparent;
}

.input-bg input {
  background-color: #3d3d3d;
  padding: 0 0 0 10px;
  color: aliceblue;
}

.adm-input-element {
  background: #222;
  height: 50px;
  padding-left: 10px;
  border-radius: 8px;
  color: #A6A6A6;
}

/* .adm-list-item-content-extra {
  background: #222;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  padding: 0px 10px 0px 10px;
  margin: 0 0 0 -5px;
  border-radius: 0 8px 8px 0;
} */

.adm-input-element::placeholder {
  color: #727272;
}

/* .adm-form-item-feedback-error {
    position: absolute;
    margin: -65px 0;
  } */

/* input {
  height: 50px;
  width: 99%;
  border-radius: 8px;
  border: 0 transparent;
  padding-left: 5px;
  font-size: 17px;
  margin-bottom: 10px;
  flex: 1;
} */

/* .ant-form-item-extra#password_extra {
  bottom: 45px;
  position: relative;
  right: 0px;
} */

/* .password {
  display: flex;
  align-items: center;
} */
/* .password .input {
  flex: auto;
}
.password .eye {
  flex: none;
  margin-left: 8px;
  padding: 4px;
  cursor: pointer;
}
.password .eye svg {
  display: block;
  font-size: 17px;
} */

.top-bar {
  flex: 0;
  color: #a8a8a8;
  position: relative;
}

.hidden {
  display: none;
}

.top-logo {
  position: sticky;
  top: -10px;
  background: rgba(255, 255, 255, 0.);
  backdrop-filter: blur(15px);
  height: 60px;
  margin: 0 0 20px 0;
  z-index: 999;
}


.investor-name {
  color: #fff;
  font-size: 20px;
  margin-left: 20px;
  width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.adm-list-card {
  margin: 30px 0;
}

.card-header .adm-list-header {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */
  margin-left: 10px;

  color: #FFFFFF;
}

.description-movement {
  /* Paragraf/Regular */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */


  color: #FFFFFF;

}

.description-register {
  /* Caption/Regular */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 16px */


  /* TB */

  color: #A6A6A6;

}

.credit .adm-list-item-content-extra {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */


  /* Verde positivo */

  color: #00FA6B;
}

.debit .adm-list-item-content-extra {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */


  /* Vermelho negativo */

  color: #FF3C30;
}

.bottom ul {
  color: rgb(255, 255, 255);
  z-index: 50;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 70px;
  text-decoration: none;
  list-style: none;
}

.backdrop {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: rgba(43, 43, 43, 0.4);
  /* Transparent black */
  z-index: 10;
  backdrop-filter: blur(5px);
  /* apply the blur effect */
}

/* Apply the blur effect */
/* .backdrop::before {
  content: "";
  position: absolute;
  top: -100px;
  left: -10px;
  width: calc(100% + 50px);
  height: calc(100% + 20px);
  filter: blur(30px);
  z-index: -1;
} */

.image-avatar {
  width: 35px;
  height: 35px;
}

.image-avatar img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
}

.adm-center-popup {
  --max-width: 100vw !important;
}

.adm-center-popup-wrap {
  background: linear-gradient(90deg, #11998E 0%, #38EF7D 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  width: 90vw !important;
}

.modal-title {
  text-align: center;
  color: #FFFFFF;
  font-family: 'Poppins';
  margin-bottom: 20px;
}

.modal-title .description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
}

.modal-title .value {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 130%;
}

.adm-form {
  border: 0px transparent;
}

.title-completed {
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 50px;

  color: #FFFFFF;
}

.amount-completed {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 36px;
  /* identical to box height, or 47px */
  margin-top: 10px;
  text-align: center;
  color: #38EF7D;
}

.data-completed {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  margin-top: 10px;
  text-align: center;

  color: #A6A6A6;

}

.return-completed {
  margin: 50px 20px;
}

.new-aport {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #38EF7D;
}

.profile-list-info .adm-space-item {
  width: 100%;
}

.profile-list-info.adm-list {
  --border-inner: solid 0px transparent;
  --border-top: solid 0px transparent;
  --border-bottom: solid 0px transparent;
}

.adm-toast-mask .adm-toast-main-icon {
  max-width: 90% !important;
}

.reset-pass {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #38EF7D;
}


.adm-result-title {
  color: #11998E;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 25px;
}

.withdrawal-input .adm-input-element {
  background-color: #fff;
}